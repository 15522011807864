/* You can add global styles to this file, and also import other style files */

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";


/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

// Automation code editor
@import 'codemirror/lib/codemirror';
@import 'codemirror/theme/material';


tag{
    border-radius: 0 !important;
}

.lookup-field.is-invalid{
    border: 1px solid var(--bs-form-invalid-border-color);
}

.p-button.p-button-text:enabled:hover{
    background-color: transparent;
}

.p-button.p-button-icon-only.p-button-rounded{
    height: 0px !important;
    border-radius: unset !important;
}

.p-button:focus,
.p-button.p-button-danger:enabled:focus{
    box-shadow: unset !important;
}

.document-markdown{
    pre{
        background-color: #272822;
        padding: 1em;
        margin: 0.5em 0;
        overflow: auto;
        border-radius: 0.3em;

        code{
            color: #f8f8f2;
        }
    }

    code:not([class*=language-]) {
        background: rgba(0,0,0,.07);
        border-radius: 3px;
        font-size: .94em;
        padding: 0 6px 2px;
        overflow-wrap: break-word;
    }

    table{
        width: 100%;

        th {
            border-bottom-color: #0000001f;
            text-align: left;
            height: 48px;
            font-size: 15px;
            font-weight: 500;
        }

        th, td{
            padding: 0;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: #0000001f;
        }

        td{
            font-size: 15px;
            height: 48px;
            text-align: left;
        }
    }
}

.p-confirm-popup{
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

// For menu component
.parent-active-route{
    font-weight: 700;
}
